<template>
  <div>
    <input type='hidden' name='alert[alert_type]' :value='alert_type'>
    <input type='hidden' name='alert[email]' :value='email'>
    <input type='hidden' name='alert[webhook_url]' :value='webhook_url'>
    <input type='hidden' name='alert[expiration_type]' :value='expiration_type'>
    <input type='hidden' name='alert[webhook_payload]' :value='webhook_payload'>
    <input type='hidden' name='alert[days_until]' :value='days_until'>
    <input type='hidden' name='alert[record_event_type]' :value='record_event_type'>
    <input type='hidden' name='alert[record_type]' :value='record_type'>
    <input type='hidden' name='alert[stop_on_trigger]' :value='stop_on_trigger'>
    <input type='hidden' name='alert[include_archived_records]' :value='include_archived_records'>

    <h3 style='margin-top: 0;'>What kind of alert is this?</h3>
    <div class='form-group'>
      <label class='required'>Event</label>
      <div class='choices'>
        <label>
          <input value='single_expiration' v-model='alert_type' type='radio'>
          <article>
            <h2>Single Expiration</h2>
            <p>Receive an alert when an expiration hits a trigger number of days</p>
          </article>
        </label>
        <label>
          <input value='expiration' v-model='alert_type' type='radio'>
          <article>
            <h2>Expiration List</h2>
            <p>Receive a list when expirations hit a trigger number of days</p>
          </article>
        </label>
        <label>
          <input value='website_monitor' v-model='alert_type' type='radio'>
          <article>
            <h2>Website Down</h2>
            <p>Receive a notification when a website appears down</p>
          </article>
        </label>
        <label>
          <input value='password_view' v-model='alert_type' type='radio'>
          <article>
            <h2>One-Time Shared Password Reveal</h2>
            <p>Receive an alert when a one-time shared password has been revealed</p>
          </article>
        </label>
        <label>
          <input value='record_change' v-model='alert_type' type='radio'>
          <article>
            <h2>Record Created/Updated/Deleted</h2>
            <p>Receive an alert when a record has been created, updated, or deleted</p>
          </article>
        </label>
        <label>
          <input value='password_change' v-model='alert_type' type='radio'>
          <article>
            <h2>Password Created/Updated</h2>
            <p>Receive an alert when a password has been created or updated</p>
          </article>
        </label>
      </div>
    </div>

    <div v-if='alert_type == "expiration" || alert_type == "single_expiration"'>

      <div class='form-group'>
        <label for='days_until' class='required'>Trigger Alert X days before Expiring</label>
        <input type='number' v-model='days_until' placeholder="" min=0>
      </div>

      <div class='form-group checkbox'>
        <input id='stop_on_trigger' type='checkbox' v-model='stop_on_trigger'>
        <label for='stop_on_trigger'>Stop Alerts After Reaching Trigger</label>
      </div>

      <div class='form-group checkbox'>
        <input id='include_archived_records' type='checkbox' v-model='include_archived_records'>
        <label for='include_archived_records'>Include Expiring Records in Museum</label>
      </div>

      <div class='form-group'>
        <label for='expiration_type' class='required'>Expiration Type</label>
        <select v-model='expiration_type'>
          <option value='all_unexpired'>Alert for All Expirations</option>
          <option value='domain'>Alert for only Domain Expirations</option>
          <option value='ssl_certificate'>Alert for only SSL Certificate Expirations</option>
          <option value='warranty'>Alert for only Warranty Expirations</option>
          <option value='article_expiration'>Alert for only Article Expirations</option>
          <option value='asset_field'>Alert for only Asset Expirations</option>
        </select>
      </div>
    </div>

    <div v-if="alert_type == 'record_change'">
      <div class='form-group'>
        <label for='record_type' class='required'>Record Type</label>
        <select v-model='record_type'>
          <option value='all_records'>Alert for All Types of Records</option>
          <option value='Company'>Alert for only {{ structure_name}}</option>
          <option value='AssetPassword'>Alert for only Passwords</option>
          <option value='Article'>Alert for only Articles</option>
          <option value='Asset'>Alert for only Assets</option>
          <option value='Website'>Alert for only Websites</option>
        </select>
      </div>
      <div class='form-group'>
        <label for='record_event_type' class='required'>When the record is...</label>
        <select v-model='record_event_type'>
          <option value='all_events'>Created, Updated, or Deleted</option>
          <option value='create_event'>Created</option>
          <option value='update_event'>Updated</option>
          <option value='delete_event'>Deleted</option>
        </select>
      </div>
    </div>

    <h3 style='margin-top: 0;'>Where should this alert be sent?</h3>

    <div class='form-group'>
      <label for='email'>Email Address</label>
      <input type='email' v-model='email' placeholder='example@example.com'>
    </div>

    <div class='form-group checkbox'>
      <input id='useWebhook' type='checkbox' v-model='useWebhook'>
      <label for='useWebhook'>Optional: Configure Webhook</label>
    </div>
    <template v-if='useWebhook'>
      <div class='form-group'>
        <label for='webhook_url'>Webhook URL</label>
        <input type='text' v-model='webhook_url'>
      </div>

      <div class='form-group'>
        <label for='webhook_payload'>Webhook Payload</label>
        <textarea placeholder="Must be valid JSON" v-model='webhook_payload'></textarea>
      </div>

      <div class='form-group'>
        <div v-if='alert_type == "expiration"' class='roler__help'>
          <h1>Variables you can use in webhooks:</h1>
          <p><b>$EXPIRATIONS_COUNT</b> = Number of expirations</p>
          <p><b>$EXPIRATIONS_HUDU_URL</b> = Link to the relevant expirations in Hudu</p>
          <p><b>$TRIGGER_DAYS</b> = Number of days set as trigger time</p>
        </div>
        <div v-if='alert_type == "single_expiration"' class='roler__help'>
          <h1>Variables you can use in webhooks:</h1>
          <p><b>$HUDU_URL</b> = Link to the relevant expiration in Hudu</p>
          <p><b>$TRIGGER_DAYS</b> = Number of days set as trigger time</p>
          <p><b>$RECORD_NAME</b> = Name of expiring record</p>
          <p><b>$COMPANY_NAME</b> = Name of expiring record's company</p>
        </div>
        <div v-if='alert_type == "website_monitor"' class='roler__help'>
          <h1>Variables you can use in webhooks:</h1>
          <p><b>$WEBSITE_NAME</b> = Name of website</p>
          <p><b>$WEBSITE_HUDU_URL</b> = Link to the relevant URL in Hudu</p>
        </div>
        <div v-if='alert_type == "password_view"' class='roler__help'>
          <h1>Variables you can use in webhooks:</h1>
          <p><b>$RECORD_NAME</b> = Name of password</p>
          <p><b>$HUDU_URL</b> = Link to the relevant URL in Hudu</p>
        </div>
        <div v-if='alert_type == "record_change"' class='roler__help'>
          <h1>Variables you can use in webhooks:</h1>
          <p><b>$RECORD_NAME</b> = Name of record</p>
          <p><b>$RECORD_TYPE</b> = Type of record</p>
          <p><b>$HUDU_URL</b> = Link to the relevant URL in Hudu</p>
          <p><b>$ACTION</b> = Name of action that occurred (create, update, delete)</p>
          <p><b>$RECORD_ID</b> = ID of record</p>
          <p><b>$COMPANY_NAME</b> = Record's Company Name (if present)</p>
          <p><b>$COMPANY_ID</b> = Record's Company ID (if present)</p>
          <p><b>$HUDU_COMPANY_URL</b> = Record's Company URL (if present)</p>
          <p><b>$ASSET_LAYOUT_ID</b> = Record's Asset Layout ID (if present)</p>
        </div>
        <div v-if='alert_type == "password_change"' class='roler__help'>
          <h1>Variables you can use in webhooks:</h1>
          <p><b>$RECORD_NAME</b> = Name of password</p>
          <p><b>$HUDU_URL</b> = Link to the relevant URL in Hudu</p>
          <p><b>$ACTION</b> = Name of action that occurred (create or update)</p>
        </div>
      </div>
      <div class='alert'>
        <p><i class='fas fa-question-circle'></i> Need help with webhooks? Here are guides setting them up for <a target="_blank" href="https://support.hudu.com/hc/en-us/articles/9611644608535">Slack</a>, <a target="_blank" href="https://support.hudu.com/hc/en-us/articles/9612316373655">Teams</a>,  <a target="_blank" href="https://support.hudu.com/hc/en-us/articles/9613342403991--N-Zapier-WebHooks">Zapier</a>, <a target="_blank" href="https://support.hudu.com/hc/en-us/articles/9609113279767">Discord</a>, and a <a target="_blank" href="https://support.hudu.com/hc/en-us/articles/9613883219991">generic guide</a></p>.</div>
    </template>



  </div>
</template>

<script>

export default {
  props: ["prealert_type",
   "structure_name", "prewebhook_url", "prerecord_event_type", "prerecord_type", "preemail", "prewebhook_payload", "preexpiration_type", "predays_until", "preinclude_archived_records", "prestop_on_trigger"],
  data: function () {
    return {
      alert_type: this.prealert_type || "expiration",
      email: this.preemail || "",
      stop_on_trigger: this.prestop_on_trigger || "",
      include_archived_records: this.preinclude_archived_records || "",
      webhook_url: this.prewebhook_url || "",
      webhook_payload:  this.prewebhook_payload || "",
      expiration_type: this.preexpiration_type || "all_unexpired",
      record_event_type: this.prerecord_event_type || "all_events",
      record_type: this.prerecord_type || "all_types",
      days_until: this.predays_until || "",
      useWebhook: (this.prewebhook_url || this.prewebhook_payload) ? true : false
    }
  }
}
</script>
